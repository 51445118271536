import { NavConfigDef as AdminNavConfigDef } from '@ade/admin/src/config';
import { NavConfigDef as CoreNavConfigDef } from '@ade/core/src/config';
import { NavConfigDef as InventoryNavConfigDef } from '@ade/inventory/src/config';
import { NavConfigDef as ProserviceNavConfigDef } from '@ade/proservice/src/config';
import { NavConfigDef as RegistryNavConfigDef } from '@ade/registry/src/config';
import { NavConfigDef as SupportNavConfigDef } from '@ade/support/src/config';
import { NavConfigDef as TacNavConfigDef } from '@ade/tac/src/config';

let navItems = [];
navItems = navItems.concat(CoreNavConfigDef.navItems);
navItems = navItems.concat(InventoryNavConfigDef.navItems);
navItems = navItems.concat(ProserviceNavConfigDef.navItems);
navItems = navItems.concat(RegistryNavConfigDef.navItems);
navItems = navItems.concat(SupportNavConfigDef.navItems);
navItems = navItems.concat(TacNavConfigDef.navItems);
navItems = navItems.concat(AdminNavConfigDef.navItems);

export const NavConfigDef = {
    navItems: navItems
};
