export const AppConfigDef = {
    app_name: 'TAC Digital Interface',
    company_name: 'Ruckus Networks, Inc.',
    company_url: 'https://www.ruckuswireless.com',
    copyright_year: (new Date()).getFullYear(),

    date_format: 'MMM. D, YYYY',
    datetime_format: 'MMM. D, YYYY h:mm A',
    time_format: 'h:mm A',
    app_skin: window.appSkin
};
