import { DashboardConfigDef as CoreDashboardConfigDef } from '@ade/core/src/config';
import { DashboardConfigDef as InventoryDashboardConfigDef } from '@ade/inventory/src/config';
// NOTE: will only include for dev for now.  Will likely be removed and only include for dkm specific app
import { DashboardConfigDef as RegistryDashboardConfigDef } from '@ade/registry/src/config';
import { DashboardConfigDef as ReportDashboardConfigDef } from '@ade/report/src/config';
import { DashboardConfigDef as SupportDashboardConfigDef } from '@ade/support/src/config';
import { DashboardConfigDef as TacDashboardConfigDef } from '@ade/tac/src/config';

let dashboardStats = [];
let dashboardComponents = [];

dashboardStats = dashboardStats.concat(CoreDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(InventoryDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(RegistryDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(ReportDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(SupportDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(TacDashboardConfigDef.dashboardStats);

dashboardComponents = dashboardComponents.concat(CoreDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(InventoryDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(RegistryDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(ReportDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(SupportDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(TacDashboardConfigDef.dashboardComponents);

export const DashboardConfigDef = {
    dashboardStats: dashboardStats,
    dashboardComponents: dashboardComponents
};
