import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

// comment out ThemeService temporarily to fix ng serve error
// import { SystemService, ThemeService } from '@ade/core/src/services';
import { SystemService } from '@ade/core/src/services';

@Component({
    // tslint:disable-next-line
    selector: 'body',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    public constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private system: SystemService,
        // private theme: ThemeService
    ) { }

    public async ngOnInit(): Promise<void> {
        let app_name = await this.system.getAppName();

        this.router.events
            .filter((event): boolean => event instanceof NavigationEnd)
            .map((): any => this.activatedRoute)
            .map((route): any => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            })
            .filter((route): boolean => route.outlet === 'primary')
            .mergeMap((route): any => route.data)
            .subscribe((event): any => this.titleService.setTitle(
                app_name + ' >> ' + event['title']));
        this.router.events.subscribe((evt): void => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        // this.theme.loadStyle('defaultSkin');
        //this.theme.loadStyle('mintSkin');

    }
}
