import { ModelConfigDef as AuthModelConfigDef } from '@ade/auth/src/config';
import { ModelConfigDef as CoreModelConfigDef } from '@ade/core/src/config';
import { ModelConfigDef as ChatModelConfigDef } from '@ade/chat/src/config';
import { ModelConfigDef as InventoryModelConfigDef } from '@ade/inventory/src/config';
import { ModelConfigDef as MqttModelConfigDef } from '@ade/mqtt/src/config';
import { ModelConfigDef as RegistryModelConfigDef } from '@ade/registry/src/config';
import { ModelConfigDef as RuckusModelConfigDef } from '@ade/ruckus/src/config';
import { ModelConfigDef as SearchModelConfigDef } from '@ade/search/src/config';
import { ModelConfigDef as SfdcModelConfigDef } from '@ade/sfdc/src/config';
import { ModelConfigDef as SystemModelConfigDef } from '@ade/system/src/config';
import { ModelConfigDef as TacModelConfigDef } from '@ade/tac/src/config';
//import { ModelConfigDef as WmsAirflowModelConfigDef } from '@ade/wmsairflow/src/config';

let models = [];
models = models.concat(AuthModelConfigDef.models);
models = models.concat(CoreModelConfigDef.models);
models = models.concat(ChatModelConfigDef.models);
models = models.concat(InventoryModelConfigDef.models);
models = models.concat(SearchModelConfigDef.models);
models = models.concat(SystemModelConfigDef.models);
models = models.concat(MqttModelConfigDef.models);
models = models.concat(RegistryModelConfigDef.models);
models = models.concat(SfdcModelConfigDef.models);
models = models.concat(RuckusModelConfigDef.models);
models = models.concat(TacModelConfigDef.models);
//models = models.concat(WmsAirflowModelConfigDef.models);

export const ModelConfigDef = {
    models: models
};
